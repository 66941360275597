import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default async ({ $cookies }) => {
  try {
    const fpPromise = FingerprintJS.load();

    async function generateUniqueId() {
      const fp = await fpPromise;
      const result = await fp.get();
      return result.visitorId;
    }

    if (!$cookies.get("uid")) {
      const uniqueId = await generateUniqueId();
      $cookies.set("uid", uniqueId, { path: "/" });
    }
  } catch (error) {
    console.error("Failed to generate unique ID using FingerprintJS:", error);
  }
};
