import { COOKIE_KEYS } from "~/config";

export const state = () => ({
  userInfo: null,
});

export const getters = {
  isAuthenticated(state) {
    return !!state.userInfo;
  },
  getToken(state) {
    return state.userInfo?.access_token?.token;
  },
  getUserInfo(state) {
    return state.userInfo;
  },
};

export const mutations = {
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },
};

export const actions = {
  setUserInfo({ commit }, payload) {
    commit("SET_USER_INFO", payload);
    if (payload?.access_token) {
      const maxAge = payload?.access_token?.expired_at
        ? (new Date(payload?.access_token?.expired_at).getTime() -
            new Date().getTime()) /
          1000
        : undefined;
      this.$cookies.set(COOKIE_KEYS.accessToken, payload.access_token, {
        maxAge,
        path: "/",
      });
    }
  },
  removeToken({ commit }) {
    commit("SET_USER_INFO", null);
    this.$cookies.remove(COOKIE_KEYS.accessToken);
  },
  async fetchUserInfo({ commit, dispatch }) {
    try {
      const res = await this.$api.user.getUserData();
      const accessToken = this.$cookies.get(COOKIE_KEYS.accessToken);

      const payload = {
        ...res.data.data,
        access_token: accessToken,
      };
      dispatch("setUserInfo", payload);
    } catch (error) {
      console.error("fetchUserInfo error", error);
    }
  },
};
