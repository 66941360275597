
import { mapGetters, mapActions } from "vuex";
import CatalogBlock from "~/components/blocks/CatalogBlock";
import UiFilter from "@/components/UI/UiFilter.vue";

export default {
  components: {
    CatalogBlock,
    UiFilter,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    pageData: {
      type: Object,
      default: () => {},
    },
    showCollectionsFilters: {
      type: Boolean,
      default: false,
    },
    showCategoriesFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterSchema: {
        min_cost_from: "",
        max_cost_to: "",
      },
      filterData: null,
      sortByOptions: [
        // { label: "POPULARITY", value: "popularity" },
        // { label: "FEATURED", value: "featured" },
        // { label: "PRICE", value: "price" },
        // { label: "DEFAULT", value: "" },
        { label: this.$t("recommended"), value: "" },
        { label: this.$t("priceFromLowestToHighest"), value: "price|asc" },
        { label: this.$t("priceFromHighestToLowest"), value: "price|desc" },
      ],
    };
  },
  async fetch() {
    await this.fetchFiltersData();
    this.filterSchema = {
      min_cost_from: "",
      max_cost_to: "",
    };
    this.filterData = JSON.parse(JSON.stringify(this.filterList));
    // Object.values(this.filterData).forEach((el) => {
    //   el.sort((a, b) => a?.sort_order - b?.sort_order);
    // });
    Object.entries(this.filterData).forEach(([key, value]) => {
      this.filterSchema[key] = value.reduce((acc, el) => {
        if (key === "collections" && this.showCollectionsFilters) {
          acc.push({
            label: el.mixedTranslation?.title,
            url: el.slug,
          });
        } else if (
          key === "categories" &&
          !this.data?.categories?.length &&
          this.showCategoriesFilters
        ) {
          acc.push({
            ...el,
            label: el.mixedTranslation?.title,
            value: el.sku,
          });
        } else if (key === "materials") {
          if (
            el.collections.find((i) =>
              this.data.collections.find((y) => y.id === i)
            ) ||
            el.categories.find((i) =>
              this.data.categories.find((y) => y.id === i)
            )
          ) {
            acc.push({
              ...el,
              label: el.mixedTranslation?.title,
              value: el.sku,
            });
          }
        }
        return acc;
      }, []);
    });
  },
  computed: {
    ...mapGetters({
      getMaterialsCategories: "general/getMaterialsCategories",
      filterList: "general/getFiltersData",
    }),
    staticFilters() {
      return {
        static_filters_title: this.data?.static_filters_title,
        static_filters: this.data?.static_filters,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchFiltersData: "general/fetchFiltersData",
    }),
  },
};
