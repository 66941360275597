export default (axios) => ({
  /**
   * Get Shipping Methods
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Orders-PostApiV1OrdersRates
   * @param data
   * @returns {*}
   */
  getShippingMethods(data) {
    return axios.post("/orders/rates", data);
  },

  /**
   * get Single Order
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Orders-GetApiV1OrdersIdNumber0
   * @param id
   * @returns {*}
   */
  getOrderByID(id, params) {
    return axios.get(`/orders/${id}`, { params });
  },
   /**
   * List of Orders
   *
   * @link https://devapi.omara.sk/apidoc/#api-Orders-GetApiV1Orders
   * @returns {*}
   */
  getUserOrders(params) {
    return axios.get("/orders", { params });
  },
  /**
   * Notify Order
   *
   * @link https://devapi.omara.sk/apidoc/#api-Orders-PatchApiV1OrdersId
   * @param data
   * @returns {*}
   */
   notifyOrder(id) {
    return axios.patch(`/orders/${id}`);
  },
  /**
   * Order Tracking
   *
   * @link https://devapi.omara.sk/apidoc/#api-Orders-GetApiV1Tracking
   * @param data
   * @returns {*}
   */
  getTrackingOrder(params) {
    return axios.get("/tracking", { params });
  },
  /**
   * Returns request
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Public-PostApiV1ReturnsRequest
   * @param data
   * @returns {*}
   */
  returnProducts(data) {
    return axios.post("/returns/request", data);
  },
});
