/* eslint-disable camelcase */
import Vue from "vue";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  localize,
} from "vee-validate";
import {
  digits,
  required,
  email,
  min,
  max,
  regex,
  required_if,
  confirmed,
} from "vee-validate/dist/rules";

localize({
  en: {
    messages: {
      required: (_field_) => `${_field_} is required`,
      min: (_, { _field_, length }) =>
        `${_field_} must be at least ${length} characters`,
      max: (_, { _field_, length }) =>
        `${_field_} must have no more than ${length} characters`,
      passwordChar: (_field_) => `Requirements: Minimum 8 characters`,
      confirmed: (_, { _field_ }) => {
        return `${_field_} field confirmation does not match`;
      },
      phone_number: (_field_) => `${_field_} is wrong`,
      email: (_field_) => `${_field_} is incorrect`,
      validate_zip: (_field_) => `${_field_} is incorrect`,
    },
  },
  sk: {
    messages: {
      required: (_field_) => `Pole ${_field_} je povinné`,
      min: (_, { _field_, length }) =>
        `${_field_} musí mať aspoň ${length} znakov`,
      max: (_, { _field_, length }) =>
        `${_field_} nesmie mať viac ako ${length} znakov`,
      passwordChar: (_field_) => `Požiadavky: Minimálne 8 znakov`,
      confirmed: (_, { _field_ }) => {
        return `${_field_} pole potvrdenia sa nezhoduje`;
      },
      phone_number: (_field_) => `${_field_} je nesprávne`,
      email: (_field_) => `${_field_} je nesprávna`,
      validate_zip: (_field_) => `${_field_} nie je spravné`,
    },
  },
  cs: {
    messages: {
      required: (_field_) => `Pole ${_field_} je povinné`,
      min: (_, { _field_, length }) =>
        `${_field_} musí mít alespoň ${length} znaků`,
      max: (_, { _field_, length }) =>
        `${_field_} nesmí mít více než ${length} znaků`,
      passwordChar: (_field_) => `Požadavky: Minimálně 8 znaků`,
      confirmed: (_, { _field_ }) => {
        return `${_field_} pole potvrzení se neshoduje`;
      },
      phone_number: (_field_) => `${_field_} je chybné`,
      email: (_field_) => `${_field_} je chybná`,
      validate_zip: (_field_) => `${_field_} je chybné`,
    },
  },
  pl: {
    messages: {
      required: (_field_) => `Pole ${_field_} jest obowiązkowe`,
      min: (_, { _field_, length }) =>
        `${_field_} musi mieć co najmniej ${length} znaków`,
      max: (_, { _field_, length }) =>
        `${_field_} nie może mieć więcej niż ${length} znaków`,
      passwordChar: (_field_) => `Wymagania: Co najmniej 8 znaków`,
      confirmed: (_, { _field_ }) => {
        return `${_field_} pole potwierdzenia nie pasuje`;
      },
      phone_number: (_field_) => `${_field_} jest nieprawidłowy`,
      email: (_field_) => `${_field_} jest nieprawidłowy`,
      validate_zip: (_field_) => `${_field_} jest nieprawidłowy`,
    },
    de: {
      messages: {
        required: (_field_) => `${_field_} ist erforderlich`,
        min: (_, { _field_, length }) =>
          `${_field_} muss mindestens ${length} Zeichen lang sein`,
        max: (_, { _field_, length }) =>
          `${_field_} darf höchstens ${length} Zeichen lang sein`,
        passwordChar: (_field_) => `Anforderungen: Mindestens 8 Zeichen`,
        confirmed: (_, { _field_ }) => {
          return `Die Bestätigung des Feldes ${_field_} stimmt nicht überein`;
        },
        phone_number: (_field_) => `${_field_} ist falsch`,
        email: (_field_) => `${_field_} ist falsch`,
        validate_zip: (_field_) => `${_field_} ist falsch`,
        fieldNotExists: (_field_) =>
          `Falsches ${_field_.toLowerCase()}: Das ${_field_.toLowerCase()} stimmt nicht mit den anderen Feldern überein.`,
      },
    },
    es: {
      messages: {
        required: (_field_) => `${_field_} es obligatorio`,
        min: (_, { _field_, length }) =>
          `${_field_} debe tener al menos ${length} caracteres`,
        max: (_, { _field_, length }) =>
          `${_field_} no debe tener más de ${length} caracteres`,
        passwordChar: (_field_) => `Requisitos: Mínimo 8 caracteres`,
        confirmed: (_, { _field_ }) => {
          return `La confirmación del campo ${_field_} no coincide`;
        },
        phone_number: (_field_) => `${_field_} es incorrecto`,
        email: (_field_) => `${_field_} es incorrecto`,
        validate_zip: (_field_) => `${_field_} es incorrecto`,
        fieldNotExists: (_field_) =>
          `Incorrecto ${_field_.toLowerCase()}: El ${_field_.toLowerCase()} no corresponde con los otros campos.`,
      },
    },
  },
});

// extend("required", { ...required, message: "The {_field_} is required" });
extend("required", { ...required });
extend("email", email);
extend("min", {
  ...min,
});
extend("max", max);
extend("confirmed", confirmed);
extend("regex", regex);
extend("required_if", required_if);

extend("digits", digits);

extend("passwordChar", {
  validate: (value) => {
    const reg = /^.{8,}$/;
    const passwordRegex = new RegExp(reg);
    return passwordRegex.test(String(value));
  },
});

extend("phone_number", {
  validate: (value) => {
    const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    const phoneRegex = new RegExp(reg);
    return phoneRegex.test(String(value));
  },
});

extend("validate_zip", {
  validate: (value) => {
    // const noSpaceValue = value.replaceAll(" ", "");
    // const reg = /^\d{5}(?:[-\s]\d{4})?$/;
    // const zipRegex = new RegExp(reg);
    // return zipRegex.test(String(noSpaceValue));
    return true; // remove zip code validation
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

export default function ({ app }) {
  localize(app.i18n.locale);
}
