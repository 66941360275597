import Vue from "vue";
import UiLazyImg from "~/components/UI/UiLazyImg.vue";
// import MarketingBlock from "~/components/blocks/MarketingBlock.vue";
// import PromotedBlock from "@/components/blocks/PromotedBlock.vue";
// import ArticlesBlock from "@/components/blocks/ArticlesBlock.vue";
// import RelatedProductBlock from "@/components/blocks/RelatedProductBlock.vue";
// import RecentlyViewedBlock from "@/components/blocks/RecentlyViewedBlock.vue";
// import InstagramBlock from "@/components/blocks/InstagramBlock.vue";
// import MainSlider from "@/components/common/MainSlider.vue";
// import RichTextBlock from "@/components/blocks/RichTextBlock.vue";
// import WishlistBlock from "@/components/blocks/WishlistBlock.vue";
// import ComparisonBlock from "@/components/blocks/ComparisonBlock.vue";
// import SalesBlock from "@/components/blocks/SalesBlock.vue";
// import Bestseller from "@/components/blocks/BestSellingBlock";
// import QuestionForm from "@/components/blocks/QuestionFormBlock";
import Catalog from "@/components/blocks/SharedCatalogBlock";

const components = {
  UiLazyImg,
  // UiLazyImg: () => import("~/components/UI/UiLazyImg.vue"),
  // Slider2Columns: MarketingBlock,
  // Promoted: PromotedBlock,
  // Articles: ArticlesBlock,
  // RelatedProductBlock,
  // RecentlyViewedBlock,
  // Instagram: InstagramBlock,
  // Slider: MainSlider,
  // RichTextBlock,
  // Wishlist: WishlistBlock,
  // Comparison: ComparisonBlock,
  // Sale: SalesBlock,
  // Bestseller,
  // QuestionForm,
  Slider2Columns: () => import("~/components/blocks/MarketingBlock.vue"),
  Promoted: () => import("@/components/blocks/PromotedBlock.vue"),
  Articles: () => import("@/components/blocks/ArticlesBlock.vue"),
  RelatedProductBlock: () =>
    import("@/components/blocks/RelatedProductBlock.vue"),
  RecentlyViewedBlock: () =>
    import("@/components/blocks/RecentlyViewedBlock.vue"),
  Instagram: () => import("@/components/blocks/InstagramBlock.vue"),
  Slider: () => import("@/components/common/MainSlider.vue"),
  RichTextModule: () => import("@/components/blocks/RichTextBlock.vue"),
  Wishlist: () => import("@/components/blocks/WishlistBlock.vue"),
  Comparison: () => import("@/components/blocks/ComparisonBlock.vue"),
  Sale: () => import("@/components/blocks/SalesBlock.vue"),
  Bestseller: () => import("@/components/blocks/BestSellingBlock"),
  QuestionForm: () => import("@/components/blocks/QuestionFormBlock"),
  Categories: () => import("@/components/blocks/CategoriesBlock"),
  MarketingCollections: () =>
    import("@/components/blocks/MarketingCollectionsBlock"),
  TopSlider: () => import("@/components/blocks/TopSliderBlock"),
  Entity: () => import("@/components/blocks/EntityBlock"),
  // Catalog: () => import("@/components/blocks/SharedCatalogBlock"),
  Catalog,
  QuizList: () => import("@/components/journey-quiz/QuizListBlock"),
  QuizBtns: () => import("@/components/journey-quiz/QuizBtnsBlock"),
  SubscriptionForm: () =>
    import("~/components/shared/forms/SubscriptionForm.vue"),
  FooterForm: () => import("~/components/blocks/SubscriptionBlock.vue"),
  CampaignInformationForm: () => import("@/components/blocks/CustomBlocks/CampaignInformationFormBlock"),
};

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});
