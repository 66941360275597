
import { mapMutations, mapGetters, mapActions } from "vuex";
import { isEqual } from "lodash";
import CardImageView from "~/components/common/CardImageView";
// import { linkBuilder } from "@/utils";
import googleAnalyticsEvents from "~/mixins/googleAnalyticsEvents";
import facebookPixelEvents from "~/mixins/facebookPixelEvents";
import useProductMixin from "~/mixins/useProductMixin";
import { getProductImages, setAttributeToElements } from "~/utils";
import useEvents from "~/mixins/useEvents";

export default {
  name: "ProductCard",
  components: {
    CardImageView,
  },
  mixins: [
    googleAnalyticsEvents,
    facebookPixelEvents,
    useProductMixin,
    useEvents,
  ],
  props: {
    data: {
      type: Object,
      default: () => ({ path: "/product/conf" }),
    },
    index: {
      type: Number,
      default: 0,
    },
    statisticItemListName: {
      type: String,
      default: "",
    },
    imageBrightnessFilter: {
      type: [String, Number],
      default: 100,
    },
    showProductTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getWishList: "wishlist/getWishList",
      getCompareList: "comparison/getCompareList",
    }),
    isInWishlist() {
      const payload = {
        value: {
          engraving: {},
          sku: this.productSKU,
        },
      };
      return this.getWishList.some((el) =>
        isEqual(
          {
            sku: el.sku,
            engraving: el.engraving,
          },
          { engraving: payload.value.engraving, sku: payload.value.sku }
        )
      );
    },
    isInComparelist() {
      const payload = {
        value: {
          engraving: {},
          sku: this.productSKU,
        },
      };
      return this.getCompareList.some((el) =>
        isEqual(
          {
            sku: el.sku,
            engraving: el.engraving,
          },
          { engraving: payload.value.engraving, sku: payload.value.sku }
        )
      );
    },
    productTitle() {
      if (this.productData.embedded) {
        return this.productData?.mixedTranslation?.title || "";
      }
      return this.showProductTitle
        ? this.productData?.mixedTranslation?.title
        : this.productData?.variant_plp_image?.mixedTranslation?.title ||
            this.productData?.mixedTranslation?.title ||
            "";
    },
    productPrice() {
      if (this.productData.embedded) {
        return this.productData?.base_cost || 0;
      }
      return Number(
        this.productData?.variant_plp_image?.cost ||
          this.productData?.base_cost ||
          0
      );
    },
    productSKU() {
      if (this.productData.embedded) {
        return this.productData?.default_sku;
      }
      return (
        this.productData?.variant_plp_image?.sku ||
        this.productData?.default_sku
      );
    },
  },
  watch: {
    data: {
      handler(val) {
        this.productData = val;
      },
      immediate: true,
    },
  },
  mounted() {
    setAttributeToElements("a.hidden-ssr-link", "tabindex", "0");
    setAttributeToElements("button.hidden-ssr-link", "tabindex", "0");
  },
  methods: {
    ...mapActions({
      setWishlist: "wishlist/setWishlist",
    }),
    ...mapMutations({
      setCompareList: "comparison/SET_COMPARE_LIST",
    }),
    getProductImages,
    async onWishlist() {
      if (!this.data.default_sku) {
        this.$alert({
          title: "Error",
          text: "No default_sku",
        });
        return;
      }
      const payload = {
        value: {
          engraving: {},
          images: this.getProductImages(this.productData)?.[0]?.url,
          sku: this.productSKU,
        },
      };

      try {
        const wishlistUniqueId = this.$store.getters.getWishListId;
        let wishlist = JSON.parse(JSON.stringify(this.getWishList));
        if (!wishlist) {
          wishlist = [];
        }
        const isExist = wishlist.find((el) =>
          isEqual(
            {
              sku: el.sku,
              engraving: el.engraving,
            },
            { engraving: payload.value.engraving, sku: payload.value.sku }
          )
        );

        const wishlistEventData = {
          product_price: this.productPrice,
          product_title: this.productTitle,
          product_id: this.data.id,
          configuration_id: this.data.default_sku,
        };

        if (!isExist) {
          wishlist.push({
            ...payload.value,
            slug: this.data?.slug,
            title: this.productTitle,
            price: this.productPrice,
            embedded: this.data?.embedded,
            translations: this.data?.translations || [],
            vendor_model: this.productData?.vendor_model,
            id: this.productData?.id,
            collections_ids: this.productData?.collections_ids || [],
            start_at_cost: this.productStartAtPrice,
          });

          this.gaAddToWishlistEvent(wishlistEventData);
          this.fbAddToWishlistEvent(wishlistEventData);
        } else {
          wishlist = wishlist.filter(
            (el) =>
              !isEqual(
                {
                  sku: el.sku,
                  engraving: el.engraving,
                },
                { engraving: payload.value.engraving, sku: payload.value.sku }
              )
          );
          this.gaRemoveFromWishlistEvent(wishlistEventData);
          this.fbRemoveFromWishlistEvent(wishlistEventData);
        }

        const { data } = await this.$api.wishlist.addItem({
          unique_id: wishlistUniqueId,
          baggage: wishlist,
        });

        const eventPayload = {
          product_id: this.productData?.id,
          configuration_id: this.productSKU,
          product_sku: this.productData?.vendor_model,
          product_price: this.productPrice,
          collection_id: this.productData?.collections_ids?.[0] || "",
        };

        if (!isExist) {
          this.addToFavoritesEvent(eventPayload);
        } else {
          this.removeFromFavoritesEvent(eventPayload);
        }

        this.setWishlist(data.data.baggage);
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
      }
    },
    async onCompare() {
      if (!this.data.default_sku) {
        this.$alert({
          title: "Error",
          text: "No default_sku",
        });
        return;
      }
      const payload = {
        value: {
          engraving: {},
          images: this.getProductImages(this.productData)?.[0]?.url,
          sku: this.productSKU,
        },
      };

      try {
        const comparisonUniqueId =
          this.$store.state.localStorage.comparlist_unique_id;
        let compareList = JSON.parse(JSON.stringify(this.getCompareList));
        if (!compareList) {
          compareList = [];
        }
        const isExist = compareList.find((el) =>
          isEqual(
            {
              sku: el.sku,
              engraving: el.engraving,
            },
            { engraving: payload.value.engraving, sku: payload.value.sku }
          )
        );
        if (!isExist) {
          compareList.push({
            ...payload.value,
            slug: this.data?.slug,
            title: this.productTitle,
            price: this.productPrice,
            translations: this.data?.translations || [],
          });
        } else {
          compareList = compareList.filter(
            (el) =>
              !isEqual(
                { sku: el.sku, engraving: el.engraving },
                { engraving: payload.value.engraving, sku: payload.value.sku }
              )
          );
        }
        const { data } = await this.$api.comparison.addItem({
          unique_id: comparisonUniqueId,
          baggage: compareList,
        });

        this.setCompareList(data.data.baggage);
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
      }
    },
    sendStatistic() {
      this.gaSelectItemEvent(this.data, {
        index: this.index,
        item_list_name: this.statisticItemListName,
      });
    },
  },
};
