
import LazyHydrate from "vue-lazy-hydration";
import useEvents from "~/mixins/useEvents";

export default {
  name: "BlocksContainer",
  components: {
    LazyHydrate,
  },
  mixins: [useEvents],
  props: {
    components: {
      type: Array,
      required: true,
    },
    pageData: {
      type: Object,
      default: () => {},
    },
    showCollectionsFilters: {
      type: Boolean,
      default: false,
    },
    showCategoriesFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageComponents: {
        "campaign-information-form": "campaign-information-form",
      },
    };
  },
  computed: {
    allComponents() {
      const blocks = this.components?.length
        ? JSON.parse(JSON.stringify(this.components))
        : [];
      if (blocks?.length) {
        blocks.forEach((item) => {
          item.code = this.pageComponents[item.name]
            ? item.name
            : item.type.identifier;
        });
      }
      return blocks;
    },
  },
  mounted() {
    this.observerOnMounted();
  },
  beforeDestroy() {
    this.observerOnBeforeUnmount();
  },
};
