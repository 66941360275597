
import { mapMutations } from "vuex";
import UiNumberInput from "~/components/UI/UiNumberInput";
import UiButton from "~/components/UI/UiButton";
import { debounce, parseJson, productQueryBuilder } from "~/utils";

export default {
  name: "CartProductCard",
  components: {
    UiButton,
    UiNumberInput,
  },
  props: {
    cartItem: {
      type: Object,
      required: true,
    },
    details: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      minQuantity: 1,
      maxQuantity: 5000,
      internalQuantity: this.cartItem.quantity,
      updateItemDebounce: () => {},
      showMore: false,
    };
  },
  computed: {
    link() {
      const query = productQueryBuilder({
        sku: this.cartItem.configuration_id,
        engraving: this.engravingOptions,
      });
      return this.localePath({
        path: `/product/${this.cartItem.slug}${query}`,
      });
    },
    isGiftProduct() {
      return this.cartItem.gift_campaign_qty && this.cartItem.gift_campaign_id;
    },
    engravingOptions() {
      return parseJson(this.cartItem.engraving_options) || {};
    },
    quantity: {
      get() {
        return this.internalQuantity;
      },
      set(value) {
        this.internalQuantity = value;
        this.updateItemDebounce({
          ...this.cartItem,
          quantity: value,
        });
      },
    },
  },
  watch: {
    "cartItem.quantity"(val) {
      this.internalQuantity = val;
    },
  },
  mounted() {
    this.updateItemDebounce = debounce(this.updateItem, 500);
  },
  methods: {
    ...mapMutations({
      closeCartDrawer: "general/CLOSE_CART_DRAWER",
    }),

    updateItem(item) {
      this.$emit("update", item);
    },

    deleteCartItem(id) {
      this.$emit("delete", id);
    },
  },
};
